<template>
  <div class="journal-list__item">
    <div class="journal-list__item-title">
      <r-text>
        {{ event.title }}
      </r-text>
      <r-text type="caption">
        {{ event.description }} | {{ event.datetime }}
      </r-text>
      <r-text
        v-if="event.error_message !== null"
        type="caption"
      >
        {{ event.error_message }}
      </r-text>
    </div>
    <span :class="`journal-list__item-icon ${event.color}`" />
    <r-button
      v-if="event.type === 'DataXlsExport' && event.success"
      icon="upload"
      :disabled="!event.file_name"
      @click="uploadTMC"
    />
  </div>
</template>

<script>
import { saveAs } from 'file-saver'

export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    isOverSpeed() {
      return this.event.type === 'over_speed'
    }
  },
  methods: {
    async uploadTMC() {
      try {
        if (!this.event?.file_name) return

        const { data } = await this.$store.dispatch('GET_BLOB_REQUEST', {
          url: `export_data_xls?file=${this.event.file_name}&format=xls`
        })

        saveAs(data, this.event.file_name)
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss">
.journal-list {
  &__item {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0.25rem;
    align-items: center;
    position: relative;

    &-icon {
      height: 12px;
      width: 12px;
      border-radius: 50%;

      &.default {
        background-color: var(--button_secondary_bg);
      }

      &.warning {
        background-color: var(--accent_warning);
      }

      &.error {
        background-color: var(--accent_error);
      }
    }

    .r-button {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
